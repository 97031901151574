import React, { useState } from "react"

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"

import { ThemeProvider } from "./src/components/contextProvider"

export const wrapPageElement = ({ element, props }) => {
  return <ThemeProvider>{element}</ThemeProvider>
}
