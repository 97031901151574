import React, { useState } from "react"

const defaultState = {
  title: "default",
  setTitle: () => {},
  subtitle: "default",
  setSubTitle: () => {},
  visible: false,
  setVisible: () => {},
}

const UserContext = React.createContext(defaultState)

export const ThemeProvider = ({children}) => {
  const [title, setTitle] = useState("John Smith")
  const [subtitle, setSubTitle] = useState("John Smith")
  const [visible, setVisible] = useState("John Smith")

  return (
    <UserContext.Provider
      value={{
        title,
        setTitle,
        subtitle,
        setSubTitle,
        visible,
        setVisible,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContext

